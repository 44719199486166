@keyframes swingOutX {
    0% {

        transform: translateX(-50%) perspective(400px) rotateX(0deg);
    }
    100% {
        transform: translateX(-50%) perspective(400px) rotateX(-90deg);
    }
}

@keyframes swingInX {
    0% {
        transform: translateX(-50%) perspective(400px) rotateX(-90deg);
    }
    100% {
        transform: translateX(-50%) perspective(400px) rotateX(0deg);
    }
}

@keyframes pulse {
    0% {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1)
    }
    50% {
        -webkit-transform: scale3d(1.05, 1.05, 1.05);
        transform: scale3d(1.05, 1.05, 1.05)
    }
    100% {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1)
    }
}

@charset 'utf-8';

@import 'settings';
@import 'foundation';
@import 'motion-ui';

@include foundation-global-styles;
//@include foundation-grid;
// @include foundation-flex-grid;
@include foundation-typography;
//@include foundation-button;
//@include foundation-forms;
// @include foundation-range-input;
//@include foundation-accordion;
//@include foundation-accordion-menu;
//@include foundation-badge;
//@include foundation-breadcrumbs;
//@include foundation-button-group;
//@include foundation-callout;
//@include foundation-close-button;
//@include foundation-menu;
//@include foundation-menu-icon;
//@include foundation-drilldown-menu;
//@include foundation-dropdown;
//@include foundation-dropdown-menu;
//@include foundation-flex-video;
//@include foundation-label;
//@include foundation-media-object;
//@include foundation-off-canvas;
//@include foundation-orbit;
//@include foundation-pagination;
//@include foundation-progress-bar;
// @include foundation-progress-element;
// @include foundation-meter-element;
//@include foundation-slider;
//@include foundation-sticky;
//@include foundation-reveal;
//@include foundation-switch;
//@include foundation-table;
//@include foundation-tabs;
//@include foundation-thumbnail;
//@include foundation-title-bar;
//@include foundation-tooltip;
//@include foundation-top-bar;
@include foundation-visibility-classes;
@include foundation-float-classes;
@include foundation-flex-classes;

@include motion-ui-transitions;
@include motion-ui-animations;

//============================================================
//====================GLOBAL CLASSES
//============================================================
body {
    background-color: #eed1a5;
}

.root {
    overflow: hidden;
}

.container {
    max-width: 1920px;
    width: 100%;
    margin: 0 auto;
    &__content {
        max-width: 940px;
        width: 100%;
        margin: 0 auto;
        position: relative;
        &--flex {
            display: flex;
            height: 100%;
            flex-direction: column;
            align-items: center;
        }
        &--small {
            max-width: 625px;
        }
    }
    &__second {
        display: none;
        @include breakpoint(large) {
            display: block;
        }
        &__banner {
            width: 100%;
            background: url("../img/home/home-banner-2.png") no-repeat;
            background-size: cover;
            background-position: center;
            transform: translateY(-10%);
            height: 250px;
            @include breakpoint(medium) {
                background-size: contain;
                height: 500px;
            }
            @include breakpoint(large) {
                height: 800px;
                transform: translateY(-20%);
            }
            @include breakpoint(xlarge) {
                height: 900px;
            }
            @include breakpoint(xxlarge) {
                height: 1041px;
            }
        }
        &__img {
            margin-top: -16%;
            width: 100%;
        }
    }
    &__three {
        overflow: hidden;
        margin-top: 40px;
        @include breakpoint(xlarge) {
            margin-top: 60px;
        }
        @include breakpoint(xxlarge) {
            margin-top: 80px;
        }
    }
}

.title {
    color: $primary-color;
    font-weight: 400;
    display: inline-block;
    position: relative;
    margin-bottom: 55px;
    font-size: rem-calc(30px);
    @include breakpoint(large) {
        font-size: rem-calc(34px);
        margin-bottom: 80px;
    }
    @include breakpoint(xlarge) {
        font-size: rem-calc(46px);
        margin-bottom: 110px;
    }
    &--bold {
        font-weight: 700;
    }
    &__bottom {
        display: block;
        background: url("../img/home/title-bottom.png") no-repeat;
        background-size: contain;
        position: absolute;
        left: 50%;
        bottom: -50px;
        width: 350px;
        height: 40px;
        transform: translateX(-50%);
        @include breakpoint(large) {
            width: 526px;
            height: 63px;
            bottom: -50px;
        }
        @include breakpoint(xlarge) {
            width: 726px;
            height: 63px;
            bottom: -60px;
        }
    }
    &:after, &:before {
        content: "";
        display: block;
        background: url("../img/home/title-ic.png") no-repeat;
        background-size: contain;
        position: absolute;
        top: 50%;
        transform: translateY(-40%);
        width: 40px;
        height: 22px;
        left: -45px;
        @include breakpoint(xlarge) {
            width: 60px;
            height: 32px;
            left: -81px;
        }
    }
    &:after {
        left: auto;
        right: -45px;
        transform: translateY(-40%) rotate(180deg);
        @include breakpoint(xlarge) {
            right: -81px;
        }
    }
    &__medium {
        line-height: 1.53;
        font-size: rem-calc(24px);
        margin-bottom: 27px;
        &:after, &:before {
            display: none;
        }
        @include breakpoint(large) {
            font-size: rem-calc(26px);
        }
        @include breakpoint(xlarge) {
            font-size: rem-calc(30px);
        }
    }
}

.letter {
    width: 55px;
    height: 55px;
    display: block;
    float: left;
    margin-right: 15px;
    margin-top: 5px;
    background-size: contain;
    background-repeat: no-repeat;
    &--s {
        background-image: url("../img/home/letter-s.png");
    }
    &--b {
        background-image: url("../img/home/letter-b.png");
    }
    &--o {
        background-image: url("../img/home/letter-o.png");
    }
}

.text {
    color: $secondary-color;
    font-size: rem-calc(16px);
    font-weight: 400;
    padding-left: 7px;
    padding-right: 7px;
    @include breakpoint(medium) {
        padding-right: 0;
        padding-left: 0;
    }
    @include breakpoint(large) {
        font-size: rem-calc(18px);
    }
    @include breakpoint(xlarge) {
        font-size: rem-calc(20px);
    }
    > p {
        line-height: 32px;
    }
    > p:not(:last-child) {
        margin-bottom: 20px;
        @include breakpoint(xlarge) {
            margin-bottom: 40px;
        }
    }
    &__medium {
        color: $secondary-color;
        font-family: $body-font-family;
        font-weight: 700;
        font-size: rem-calc(18px);
        line-height: 1.44;
        @include breakpoint(large) {
            font-size: rem-calc(28px);
        }
        @include breakpoint(xlarge) {
            font-size: rem-calc(34px);
        }
    }
}

.decor {
    width: 64px;
    height: 24px;
    display: block;
    margin: 25px auto;
    background: url("../img/home/decor.png") no-repeat;
    background-size: contain;
    @include breakpoint(large) {
        margin: 35px auto;
        width: 107px;
        height: 39px;
    }
    @include breakpoint(xlarge) {
        margin: 50px auto;
    }
}

//url("../img/home/bg2.png"),
.parchment-bg {
    background-color: transparent;
    position: relative;
    z-index: 8;
    margin-top: -60px;
    padding-top: 80px;
    background-image: url("../img/home/bg_title.jpg");
    background-size: contain;
    background-position: center -70px;
    padding-bottom: 100px;
    //transform: translateY(-45px);
    &:after, &:before {
        content: "";
        display: block;
        position: absolute;
        top: -100px;
        bottom: -70px;
        left: 0;
        z-index: -1;
        width: 340px;
        background-image: url("../img/home/left-swadow.png");
        background-repeat: repeat-y;
    }
    &:before {
        left: auto;
        right: 0;
        background-image: url("../img/home/right-shadow.png");
    }
    &--top, &--bottom {
        //position: absolute;
        //top: 0;
        //bottom: 0;
        //left: 0;
        //right: 0;
        //background-image: url("../img/home/fone.png");
    }
    &--top {

    }
    &--bottom {
    }
}

.button {
    display: block;
    border: none;
    outline: none;
    box-shadow: none;
    -webkit-appearance: none;
    position: relative;
    width: 300px;
    height: 100px;
    @include breakpoint(large) {
        width: 200px;
        height: 61.7px;
    }
    @include breakpoint(xxlarge) {
        width: 300px;
        height: 100px;
    }
    &--magic {
        .sparkle-canvas {
            z-index: -1;
            left: 50% !important;
            transform: translate(-50%, -50%);
            top: 50% !important;
            border-radius: 40%;
        }
    }
    &--gold {
        .img {
            display: block;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            z-index: 1;
            background: url("../img/Play-Free-Now4.png") no-repeat;
            background-size: contain;
        }
        .text {
            position: absolute;
            display: block;
            z-index: 10;
            background: transparent;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            text-align: center;
            text-shadow:
            -1px -1px 0 #693a13,
            1px -1px 0 #693a13,
            -1px 1px 0 #693a13,
            1px 1px 0 #693a13,
            0 3px 1px rgba(255, 255, 255, 0.28);

            color: white;
            font-weight: 700;
            font-size: rem-calc(34px);
            line-height: rem-calc(85px);
            @include breakpoint(large) {
                font-size: rem-calc(18px);
                line-height: 3.8rem;
            }
            @include breakpoint(xxlarge) {
                font-size: rem-calc(34px);
                line-height: rem-calc(85px);
            }
        }
    }
    &__primary {
        width: 321px;
        height: 74px;
        background-color: transparent;
        background-image: url("../img/home/btn-bg-primary.png");
        background-repeat: no-repeat;
        background-size: cover;
        text-shadow: 0 4px 1px rgba(96, 39, 4, 0.28);
        color: white;
        font-weight: 700;
        font-size: rem-calc(20px);
        text-align: center;
        line-height: 74px;
        .shine {
            top: 0;
            bottom: 0;
        }
        @include breakpoint(xxlarge) {
            font-size: rem-calc(22px);
        }
        &:hover, &:focus {
            color: #fff;
        }
        &--gold {
            background-image: url("../img/home/btn-bg-primary-gold.png");
        }
    }
    &--content {
        width: 300px;
        height: 100px;
        .text {
            font-size: rem-calc(34px);
            line-height: rem-calc(85px);
        }
    }

}

.shine {
    position: absolute;
    overflow: hidden;
    display: block;
    z-index: 5;
    top: 15px;
    bottom: 22%;
    left: 10px;
    right: 10px;
    @include breakpoint(xlarge) {
        //bottom: 25px;
    }
}

.shine:before {
    content: "";
    position: absolute;
    top: -110%;
    left: -210%;
    width: 200%;
    height: 200%;
    opacity: 0;
    //transform: rotate(30deg);

    background: rgba(255, 255, 255, 0.13);
    background: linear-gradient(
                    to right,
                    rgba(255, 255, 255, 0.13) 0%,
                    rgba(255, 255, 255, 0.13) 77%,
                    rgba(255, 255, 255, 0.5) 92%,
                    rgba(255, 255, 255, 0.0) 100%
    );
}

/* Hover state - trigger effect */

.button:hover .shine:before {
    opacity: 1;
    top: -30%;
    left: -30%;
    transition-property: left, top, opacity;
    transition-duration: 0.7s, 0.7s, 0.15s;
    transition-timing-function: ease;
}

/* Active state */

.shine:active:before {
    opacity: 0;
}

.animated {
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both
}

.pulse {
    -webkit-animation-name: pulse;
    animation-name: pulse
}

.form__group {
    position: relative;
    margin-bottom: 17px;
    &__icon {
        position: absolute;
        left: 22px;
        top: 14px;
        display: block;
        width: 30px;
        height: 30px;
        background-image: url("../img/home/sprite-input.png");
        background-repeat: no-repeat;
        &--message {
            background-position: 1px 4px;
        }
        &--name {
            background-position: 0px -74px;
        }
        &--pass {
            height: 32px;
            background-position: 1px -146px;
        }
    }
}

.form-control {
    border: none;
    outline: none;
    box-shadow: none;
    background-color: transparent;
    width: 100%;
    height: 58px;
    color: $primary-color;
    font-family: $body-font-family;
    font-size: rem-calc(22px);
    font-weight: 700;
    background-image: url("../img/home/input-bg.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    padding-left: 70px;
    padding-right: 20px;
    transition: all .5s ease;
    &:focus {
        background-image: url("../img/home/input_focus.png");
    }
    /* Webkit */
    &::-webkit-input-placeholder {
        color: $primary-color;
    }
    /* Firefox 4-18 */
    &:-moz-placeholder {
        color: $primary-color;
    }
    /* Firefox 19+ */
    &::-moz-placeholder {
        color: $primary-color;
    }
    /* IE10+ */
    &:-ms-input-placeholder {
        color: $primary-color;
    }
}

.captcha-box {
    display: flex;
    justify-content: space-between;
    &__img {
        width: 147px;
        height: 58px;
        line-height: 58px;
        text-align: center;
        background-image: url("../img/home/captcha-box__img.png");
        background-repeat: no-repeat;
        background-size: 100% 100%;
    }
    .form__group {
        width: 209px;
        .form-control {
            background-image: url("../img/home/input-bg-sm.png");
            background-repeat: no-repeat;
            background-size: 100% 100%;
        }
    }
    .form-control {
        padding: 0;
        text-align: center;
    }
}

.logo {
    margin: 0;
    display: block;
    max-width: 403px;
    max-height: 161px;
    width: 100%;
    height: 100%;
    max-width: 480px;
    max-height: 160px;

    @include breakpoint(large) {
        max-width: 374px;
        max-height: 120px;
    }
    @include breakpoint(xlarge) {
        max-width: 480px;
        max-height: 160px;
    }
    @include breakpoint(xxlarge) {
        max-width: 566px;
        max-height: 226px;
    }
    a {
        display: block;
        width: 100%;
        height: 100%;
        background: url("../img/home/logo-lg.png") center no-repeat;
        background-size: cover;
    }
}

.btn-flex {
    display: flex;
    padding-top: 38px;
    padding-bottom: 60px;
    @include breakpoint(large) {
        margin: 0 -40px;
        padding-bottom: 80px;
    }
    @include breakpoint(xlarge) {
        padding-bottom: 100px;
    }
    .button {
        margin: 0 10px;
        @include breakpoint(large) {
            margin: 0 15px;
        }
    }
}

.navigation {
    position: relative;
    margin: 0 auto;
    z-index: 9999;
    width: 100%;
    background: url("../img/home/bg-nav.png") no-repeat;
    background-size: contain;
    max-width: 745px;
    display: none;
    &.headroom {
        display: none !important;
    }
    @include breakpoint(large) {
        background-size: contain;
        max-width: 860px;
        &.headroom {
            display: block !important;
        }
    }
    @include breakpoint(xlarge) {
        background-size: cover;
        max-width: 963px;
    }
    &--absolute {
        position: absolute;
        left: 50%;
        bottom: 5%;
        z-index: 777;
        transform: translateX(-50%);
    }
    &:after, &:before {
        content: "";
        display: block;
        position: absolute;
        width: 69px;
        height: 60px;
        left: -47px;
        top: 3px;
        background-image: url("../img/home/left-nav.png");
        background-repeat: no-repeat;
        background-size: contain;
        @include breakpoint(large) {
            top: 1px;
        }
        @include breakpoint(xxlarge) {
            top: 3px;
        }
    }
    &:before {
        left: auto;
        right: -50px;
        background-image: url(../img/home/right-nav.png);
        transform: rotate3d(0, 10, 0, 180deg);
    }
    &__list {
        padding: 0;
        margin: 0;
        display: flex;
        justify-content: space-around;
        padding-left: 15px;
        padding-right: 15px;
        height: 68px;
        width: 100%;
        @include breakpoint(large) {
            height: 83px;
        }
        &__item {
            list-style: none;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            margin-top: -7px;
            &:not(:last-child):after {
                content: '';
                display: inline-block;
                width: 1px;
                position: absolute;
                left: 100%;
                top: 50%;
                transform: translateY(-50%);
                margin-left: 20px;
                height: 20%;
                background: rgba(68, 68, 68, 1);
                background: -moz-linear-gradient(top, rgba(68, 68, 68, 1) 0%, rgba(0, 0, 0, 1) 49%, rgba(0, 0, 0, 1) 55%, rgba(68, 68, 68, 1) 100%);
                background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(68, 68, 68, 1)), color-stop(49%, rgba(0, 0, 0, 1)), color-stop(55%, rgba(0, 0, 0, 1)), color-stop(100%, rgba(68, 68, 68, 1)));
                background: -webkit-linear-gradient(top, rgba(68, 68, 68, 1) 0%, rgba(0, 0, 0, 1) 49%, rgba(0, 0, 0, 1) 55%, rgba(68, 68, 68, 1) 100%);
                background: -o-linear-gradient(top, rgba(68, 68, 68, 1) 0%, rgba(0, 0, 0, 1) 49%, rgba(0, 0, 0, 1) 55%, rgba(68, 68, 68, 1) 100%);
                background: -ms-linear-gradient(top, rgba(68, 68, 68, 1) 0%, rgba(0, 0, 0, 1) 49%, rgba(0, 0, 0, 1) 55%, rgba(68, 68, 68, 1) 100%);
                background: linear-gradient(to bottom, rgba(68, 68, 68, 1) 0%, rgba(0, 0, 0, 1) 49%, rgba(0, 0, 0, 1) 55%, rgba(68, 68, 68, 1) 100%);
                filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#444444', endColorstr='#444444', GradientType=0);
                box-shadow: 0px 5px 6px 0px rgba(0, 0, 0, 0.5);
                @include breakpoint(large) {
                    margin-left: 20px;
                    height: 30%;
                }
                @include breakpoint(xlarge) {
                    margin-left: 30px;
                }
            }
            &:before {
                content: "";
                display: inline-block;
                opacity: 0;
                height: 22px;
                position: absolute;
                left: 0;
                right: 0;
                bottom: 20px;
                transition: all .4s ease;
            }
            &.is-active, &:hover {
                &:before {
                    opacity: 1;
                    background: url("../img/home/nav_hover.png") no-repeat;
                    background-size: 100% 100%;
                    background-position: bottom;
                }
            }
        }
        &__link {
            display: inline-block;
            text-shadow: 0 2px 2px rgba(0, 0, 0, 0.77);
            color: white;
            font-family: $seondary-font-family;
            font-weight: 700;
            position: relative;
            z-index: 666;
            text-transform: uppercase;
            font-size: rem-calc(13px);
            transition: color .5s ease;
            @include breakpoint(large) {
                font-size: rem-calc(16px);
            }
            &:focus, &:hover {
                color: white;
            }
        }
    }
    //mobile
    &__mobile {
        display: block;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        bottom: 0;
        background-color: #f3d6aa;
        background-image: url(../img/home/bg2.png), url(../img/home/bg.png);
        background-size: cover;
        background-position: center;
        z-index: 9999;
        transform: translateX(-100%);
        opacity: 0;
        &__list {
            padding: 0;
            margin: 0;
            display: flex;
            flex-direction: column;
            align-items: center;
            height: 100%;
            li {
                list-style: none;
                display: inline-block;
                margin: 10px 0;
                a {
                    display: block;
                    font-weight: 700;
                    @include breakpoint(medium) {
                        font-size: rem-calc(20px);
                    }
                }
            }
        }
        &__logo {
            margin: 20px 0;
            margin-top: 25px;
            a {
                display: block;
                width: 100%;
                height: 130px;
                background: url("../img/home/logo-lg.png") center no-repeat;
                background-size: contain;
                margin: 0 auto;
            }
        }
    }
}

.pagination {
    text-align: center;
    &__list {
        padding: 0;
        margin: 0;
        display: inline-block;
        &__item {
            list-style: none;
            display: inline-block;
            margin: 0 10px;
            position: relative;
            width: 38px;
            height: 44px;
            cursor: pointer;
            &--prev, &--next {
                width: auto;
                display: none;
                @include breakpoint(medium) {
                    display: inline-block;
                }
            }
            &--prev:after, &--next:after {
                display: none !important;
            }
            &.current {
                &:after {
                    content: "";
                    display: block;
                    position: absolute;
                    background-image: url("../img/home/hex-active.png");
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: 100% 100%;
                    top: 0;
                    left: 0;
                    bottom: 0;
                    right: 0;
                }
                a {
                    color: #795a56;
                }
            }
            &:hover {
                a {
                    color: #795a56;
                }
                &:after {
                    content: "";
                    display: block;
                    position: absolute;
                    background-image: url("../img/home/hex-active.png");
                    background-repeat: no-repeat;
                    background-size: 100% 100%;
                    top: 0;
                    left: 0;
                    bottom: 0;
                    right: 0;
                }
            }
        }
        &__link {
            display: block;
            height: 100%;
            width: 100%;
            font-family: $seondary-font-family;
            font-size: 24px;
            font-weight: 700;
            line-height: 44px;
            text-align: center;
            color: $primary-color;
        }
    }
}

.filter-panel {
    margin: 0;
    //background-color: rgba(192, 175, 150, 0.2);
    //border: 1px solid #b09567;
    padding: 8px 0;
    margin-bottom: 20px;
    position: relative;
    @include breakpoint(medium) {
        background-image: url("../img/home/filter_frame.png");
        background-repeat: repeat-x;
        padding: 8px 20px;
        &:after, &:before {
            content: "";
            display: block;
            position: absolute;
            left: -25px;
            top: 0;
            width: 25px;
            height: 50px;
            background-image: url("../img/home/filter_angle.png");
            background-repeat: no-repeat;
            background-size: contain;
        }
        &:after {
            left: auto;
            right: -25px;
            transform: rotate(180deg);
        }
    }
    @include breakpoint(large) {
        margin-bottom: 25px;
        padding: 8px 25px;
    }
    @include breakpoint(xlarge) {
        margin-bottom: 45px;
    }

    &--flex-center {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        .filter-panel__item {
            float: none;
        }
    }
    &__item {
        position: relative;
        list-style: none;
        display: inline-block;
        &.last {
            float: right;
            &:after {
                width: 14px;
                height: 15px;
                margin-bottom: -2px;
                background-image: url(../img/home/filter_icon_last_child.png);
                margin-left: 7px;
            }
        }
        &:after {
            content: "";
            width: 5px;
            height: 5px;
            display: inline-block;
            background-image: url(../img/home/pimka.png);
            background-repeat: no-repeat;
            background-size: contain;
            margin: 0 7px;
            @include breakpoint(medium) {
                margin: 0 10px;
                width: 7px;
                height: 7px;
            }
            @include breakpoint(large) {
                margin: 0 12px;
                width: 9px;
                height: 9px;
            }
            @include breakpoint(xlarge) {
                margin: 0 15px;
            }
        }
        &:last-child:after {
            display: none;
        }
        &.is-active, &:hover {
            .filter-panel__link {
                color: #a7732c;
            }
        }
    }
    &__link {
        font-family: Cinzel;
        font-weight: 900;
        line-height: 32px;
        color: $primary-color;
        font-size: rem-calc(13px);
        @include breakpoint(medium) {
            font-size: rem-calc(14px);
        }
        @include breakpoint(large) {
            font-size: rem-calc(15px);
        }
        @include breakpoint(xlarge) {
            font-size: rem-calc(16px);
        }
    }
}

.full_info {
    display: flex;
    align-items: center;
    margin-top: 30px;
    &__link {
        color: $primary-color;
        font-size: rem-calc(20px);
        font-weight: 700;
        line-height: 1.6;
        text-decoration: underline;
    }
    &__icon {
        display: inline-block;
        width: 50px;
        height: 51px;
        background: url("../img/home/full_info.png") no-repeat;
        background-size: contain;
    }
}

// прилипающий хедер
.headroom--pinned.headroom--not-top {
    display: block;
    position: fixed !important;
    z-index: 9999;
    top: 0;
    bottom: auto !important;
    transform-origin: top;
    backface-visibility: visible !important;
    animation-name: swingInX;
    animation-duration: .7s;
    animation-fill-mode: both;
}

.headroom--unpinned.headroom--not-top {
    position: fixed !important;
    z-index: 9999;
    top: 0;
    bottom: auto !important;
    transform-origin: top;
    animation-name: swingOutX;
    backface-visibility: visible !important;
    animation-duration: .7s;
    animation-fill-mode: both;
}

.ui-nav {
    cursor: pointer;
    &__close {
        &.close {
            position: absolute;
            right: 15px;
            top: 15px;
        }
    }
    &__open {
        position: fixed;
        top: 15px;
        right: 15px;
        display: inline-block;
        z-index: 888;
        width: 61px;
        height: 51px;
        background: url("../img/home/open_nav.png") no-repeat;
        background-size: contain;
        @include breakpoint(large) {
            width: 81px;
            height: 71px;
        }
    }
    @include breakpoint(large) {
        display: none;
    }
}

.close {
    position: relative;
    display: inline-block;
    width: 20px;
    height: 20px;
    overflow: hidden;
    &:hover::before, &:hover::after {
        background: #4e9031;
    }
    &::before, &::after {
        content: '';
        position: absolute;
        height: 2px;
        width: 100%;
        top: 50%;
        left: 0;
        margin-top: -1px;
        background: #000;
    }
    &::before {
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
    }
    &::after {
        -ms-transform: rotate(-45deg);
        transform: rotate(-45deg);
    }
    &.big {
        -ms-transform: scale(3);
        transform: scale(3);
    }
    &.hairline::before, &.hairline::after {
        height: 1px;
    }
    &.thick::before, &.thick::after {
        height: 4px;
        margin-top: -2px;
    }
    &.black::before, &.black::after {
        height: 8px;
        margin-top: -4px;
    }
    &.heavy::before, &.heavy::after {
        height: 12px;
        margin-top: -6px;
    }
    &.pointy::Before, &.pointy::after {
        width: 200%;
        left: -50%;
    }
    &.rounded::before, &.rounded::after {
        border-radius: 5px;
    }
    &.blades::before, &.blades::after {
        border-radius: 5px 0;
    }
    &.warp::before, &.warp::after {
        border-radius: 120% 0;
    }
    &.fat::before, &.fat::after {
        border-radius: 100%;
    }
}

//============================================================
//====================HOME
//============================================================
.top__banner {
    position: relative;
    z-index: 555;
    &__img {
        width: 100%;
        display: none;
        @include breakpoint(large) {
            display: inline-block;
        }
        &__bg {
            display: block;
            height: 602px;
            background: url("../img/home/mobile_banner.png") no-repeat;
            background-size: cover;
            background-position: center;
            @include breakpoint(large) {
                display: none;
            }
        }
    }
    &__smoke {
        overflow: hidden;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }
    .logo, .btn-box {
        position: absolute;
        z-index: 777;
        top: 50%;
        left: 50%;

    }
    .logo {
        top: 0;
        transform: translate(-50%, 10%);
    }
    .btn-box {
        top: 50%;
        transform: translateX(-50%);
        @include breakpoint(large) {
            top: 40%;
        }
        @include breakpoint(xlarge) {
            top: 35%;
        }
        .button {
            position: relative;
            &:after {
                opacity: 0;
                transition: all .6s ease;
                box-shadow: 0 0 40px 60px rgba(207, 179, 132, 0.5);

                content: "";
                display: block;
                position: absolute;
                left: 0;
                right: 0;
                top: 20px;
                bottom: 20px;
                border-radius: 100%;
                z-index: -1;
            }
            &:hover {
                animation-name: pulse;
                animation-duration: .7s;
                animation-fill-mode: both;
                animation-iteration-count: infinite;
                &:after {
                    opacity: 1;
                    box-shadow: 0 0 40px 60px rgba(207, 179, 132, 0.5);
                }
            }
        }
    }
    &--small {
        max-height: 450px;
        background: url("../img/home/banner_small.png");
        background-size: cover;
        background-position: center;
        @include breakpoint(medium) {
            height: 450px;
        }
        .top__banner__img__bg {
            height: 450px;
        }
        > img {
            display: none;
            height: 100%;
        }
        .navigation--absolute {
            bottom: 9%;
            @include breakpoint(xxlarge) {
                bottom: 11%;
            }
        }
        .btn-box.button--magic {
            @include breakpoint(large) {
            }
            @include breakpoint(xlarge) {
                top: 40%;
            }
            @include breakpoint(xxlarge) {
                top: 45%;
            }
        }
        .logo {
            @include breakpoint(large) {
                max-width: 392px;
                max-height: 140px;
            }
            @include breakpoint(xxlarge) {
                max-width: 392px;
                max-height: 170px;
                top: 15px;
            }
        }
    }
}

.home {
    .parchment-bg {
        position: relative;
        &--top {
            display: block;
            position: absolute;
            top: -70px;
            bottom: 0;
            left: 0;
            right: 0;
            background-image: url("../img/home/bg2.png");
            background-repeat: no-repeat;
            background-size: contain;
        }
    }
    &__banner {
        background-image: url("../img/home/banner-home.png");
        background-size: contain;
        background-repeat: no-repeat;
        position: relative;
        z-index: 10;
        @include breakpoint(996px) {
            background-size: cover;
            height: 387px;
        }
        @include breakpoint(large) {
            height: 400px;
        }
        @include breakpoint(xlarge) {
            height: 467px;
        }
        @include breakpoint(1280px) {
            height: 498px;
        }
        @include breakpoint(1366px) {
            height: 531px;
        }
        @include breakpoint(xxlarge) {
            height: 560px;
        }
        @include breakpoint(xxlarge) {
            height: 622px;
        }
        @include breakpoint(1700px) {
            height: 747px;
        }
    }
    &__first {
        padding-left: 15px;
        padding-right: 15px;
    }
}

.container {
    &__first {
        position: relative;
    }
}

.main {
    position: relative;
    z-index: 8;
    .parchment-bg {
        padding-top: 70px;
    }
}

.banner-carts {
    position: absolute;
    bottom: 10%;
    left: 50%;
    transform: translateX(-50%);
    z-index: 666;
    width: 350px;
    overflow: hidden;
    padding: 20px;
    box-sizing: content-box;
    display: none;

    @include breakpoint(xlarge) {
        display: inline-block;
    }
    @include breakpoint(xlarge) {

    }
    @include breakpoint(xxlarge) {
        width: 500px;
    }
    .sparkle-canvas {
        z-index: -1;
        top: -2% !important;
        left: 50% !important;
        transform: translateX(-50%);
        transform-origin: left;
        border-radius: 100%;
    }
    &__canvas {
        img {
            @include breakpoint(xxlarge) {
                margin-bottom: -25px;
            }
        }
    }
}

.video {
    position: relative;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    height: 280px;
    margin-top: 37px;
    margin-bottom: 15px;
    @include breakpoint(400px) {
        margin-bottom: 10px;
        height: 280px;
    }
    @include breakpoint(480px) {
        height: 390px;
    }
    @include breakpoint(medium) {
        width: 500px;
        height: 400px;
        margin-top: 47px;
        margin-bottom: 44px;
    }
    @include breakpoint(large) {
        width: 659px;
        height: 527px;
        margin-right: -15px;
        margin-left: -15px;
        margin-top: 57px;
        margin-bottom: 54px;
    }
    &__wallpaper {
        position: absolute;
        left: 13px;
        right: 13px;
        bottom: 13px;
        top: 15px;
        z-index: -1;
        background-image: url("../img/home/video__wallpaper.png");
        background-repeat: no-repeat;
        background-size: 100% 100%;
        @include breakpoint(xlarge) {
            top: 20px;
            right: 20px;
            bottom: 20px;
        }
        &_2 {
            background-image: url("../img/home/video__wallpaper_2.png");
        }
    }
    &__title {
        color: $primary-color;
        font-family: $seondary-font-family;
        font-weight: 900;
        text-transform: uppercase;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        margin-top: 10px;
        font-size: rem-calc(12px);
        @include breakpoint(500px) {
            font-size: rem-calc(15px);
        }
        @include breakpoint(medium) {
            margin-top: 15px;
            font-size: rem-calc(13px);
        }
        @include breakpoint(large) {
            font-size: rem-calc(23px);
        }
    }
    &__frame {
        position: absolute;
        top: 40px;
        bottom: 0;
        left: 0;
        right: 0;
        background: url("../img/home/video__decor.png") no-repeat;
        background-size: contain;
    }
    &__decor {
        bottom: 0;
        left: 0;
        right: 0;
        background: url(../img/home/frame__video.png) no-repeat;
        background-size: 100% 100%;
        width: 100%;
        height: 90%;
        position: absolute;
        overflow: hidden;
    }
    &__rectangle {
        display: none;
        @include breakpoint(large) {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            display: block;
            background: url("../img/home/video-rectangle.png") no-repeat;
            background-size: contain;
        }
    }
    &__play {
        display: block;
        background-image: url("../img/home/play.png");
        background-repeat: no-repeat;
        background-size: contain;
        position: absolute;
        left: 50%;
        top: 55%;
        width: 100px;
        height: 100px;
        transform: translate(-50%, -50%);
        cursor: pointer;
        transition: background .4s ease;
        @include breakpoint(xlarge) {
            width: 120px;
            height: 120px;
            transform: translate(-50%, -50%);
        }
        &:hover {
            background-image: url("../img/home/play__hover.png");
        }
    }
    &__image {
        display: none;
        @include breakpoint(xlarge) {
            display: block;
            position: absolute;
            top: 0;
            &__left {
                left: 0;
                width: 358px;
                height: 428px;
                background: url("../img/home/video__left_img.png") no-repeat;
                transform: translate(-100%, 20%);
            }
            &__right {
                right: 0;
                width: 290px;
                height: 347px;
                background: url("../img/home/video__right_img.png") no-repeat;
                transform: translate(100%, 30%);
            }
        }
    }
}

.container-slider {
    position: relative;
    margin-top: 30px;
    //z-index: -1;
    @include breakpoint(large) {
        margin-top: 45px;
    }
    &__frame {
        display: none;
        @include breakpoint(768px) {
            display: block;
            position: absolute;
            top: -13px;
            bottom: -16px;
            left: -60px;
            right: -60px;
            background: url("../img/home/slider__frame.png") -1px 6px no-repeat;
            background-size: contain;
        }
    }
    &__nav {
        display: none;
        @include breakpoint(768px) {
            width: 55px;
            height: 120px;
            display: block;
            position: absolute;
            left: 0;
            top: 50%;
        }
        &--left {
            background: url("../img/home/container-slider__nav--left.png") no-repeat;
            background-size: contain;
            transform: translate(-100%, -50%);
        }
        &--right {
            background: url("../img/home/container-slider__nav--right.png") no-repeat;
            background-size: contain;
            left: auto;
            right: 0;
            transform: translate(100%, -50%);
        }
    }
    &__img {
        display: none;
        @include breakpoint(large) {
            display: block;
            position: absolute;
            left: 0;
            bottom: -50px;
            background-repeat: no-repeat;
            background-size: cover;
        }
        &--left {
            background-image: url("../img/home/slider__left.png");
            background-position: 141px;
            width: 100%;
            height: 100%;
            transform: translateX(-100%);
            @include breakpoint(large) {
                background-position: 169px;
            }
            @include breakpoint(xlarge) {
                max-width: 525px;
                max-height: 431px;
            }
            @include breakpoint(xxlarge) {

            }
        }
        &--right {
            background-image: url("../img/home/slider__right.png");
            background-position: -130px;
            bottom: auto;
            top: -16px;
            left: auto;
            right: 0;
            max-width: 530px;
            max-height: 530px;
            width: 100%;
            height: 100%;
            top: auto;
            transform: translateX(100%);
            @include breakpoint(large) {
                background-size: auto;
                background-position: 7px;
                bottom: 160px;
            }
            @include breakpoint(xlarge) {
                background-position: 7px;
                bottom: 160px;
            }
            @include breakpoint(xxlarge) {
                background-position: 7px;
                bottom: 160px;
            }
        }
    }
}

.common__slider {
    font-weight: 700;
    font-style: italic;
    color: #64341b;
    font-family: $body-font-family;
    font-size: rem-calc(14px);
    text-align: center;
    margin-top: 0;
    > span {
        font-weight: 400;
    }
    @include breakpoint(768px) {
        font-size: rem-calc(16px);
        margin-top: 20px;
    }
    @include breakpoint(large) {
        font-size: rem-calc(18px);
        margin-top: 25px;
    }
    @include breakpoint(xlarge) {
        font-size: rem-calc(20px);
        margin-top: 30px;
    }
}

//============================================================
//==================== FOOTER
//============================================================
.pre-footer {
    height: 70px;
    width: 100%;
    display: block;
    margin-top: -60px;
    position: relative;
    z-index: 666;
    transform: translateY(0);
    //transform: translateY(36%);
    background-image: url("../img/home/bg-footer-ending.png");
    background-repeat: no-repeat;
    background-position: center -7px;
}

.footer {
    position: relative;
    width: 100%;
    height: 470px;
    background-size: cover;
    z-index: 777;
    @include breakpoint(xxlarge) {
        height: 700px;
    }
    &__bg {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: url("../img/home/footer-bg.png") bottom no-repeat;
        background-size: cover;
    }
    &__icon {
        width: 120px;
        height: 60px;
        position: absolute;
        z-index: 10;
        background-repeat: no-repeat;
        background-size: contain;
        &--bottom-left {
            bottom: 0;
            left: 0;
            background-image: url("../img/home/footer-left-bottom.png");
        }
        &--bottom-right {
            bottom: 0;
            right: 0;
            background-image: url("../img/home/footer-right-bottom.png");
        }
        &--top-left {
            top: 0;
            left: 0;
            background-image: url("../img/home/footer-left-top.png");
        }
        &--top-right {
            top: 0;
            right: 0;
            background-image: url("../img/home/footer_right_top.png");
        }
    }
    &__content {
        max-height: 440px;
        max-width: 381px;
        margin: 0 auto;
        width: 100%;
        height: 100%;
        position: relative;
        z-index: 20;
        background: url("../img/home/footer-fabric.png") no-repeat;
        background-size: contain;
        @include breakpoint(xxlarge) {
            max-width: 502px;
        }
        > div {
            transform: translateX(-1%);
        }
        &__title {
            color: white;
            font-family: $seondary-font-family;
            font-size: rem-calc(24px);
            font-weight: 700;
            line-height: 1.13;
            text-align: center;
            padding-top: 38px;
            @include breakpoint(large) {
                font-size: rem-calc(26px);
                padding-top: 43px;
            }
            @include breakpoint(xlarge) {
                font-size: rem-calc(32px);
                padding-top: 53px;
            }
            @include breakpoint(xxlarge) {
                font-size: rem-calc(38px);
                padding-top: 48px;
            }
        }
        &__text {
            color: #cbe8ef;
            font-family: $body-font-family;
            font-weight: 700;
            line-height: 1.43;
            font-size: rem-calc(13px);
            text-align: center;
            margin: 30px 0;
            @include breakpoint(xlarge) {
                font-size: rem-calc(14px);
                margin: 20px 0;
            }
            @include breakpoint(xxlarge) {
                font-size: rem-calc(16px);
                margin: 35px 0;
            }
        }
        .button {
            margin: 0 auto;
            transform: translateX(-4%);
            @media screen and (max-width: 1025px) {
                width: 293px;
                height: 80px;
                .img {
                    background-position: center;
                }
                .text {
                    line-height: 4.9rem;
                    font-size: rem-calc(26px);
                }
            }
        }

    }
    &__social {
        position: absolute;
        z-index: 777;
        left: 50%;
        transform: translateX(-50%);
        bottom: 40px;
        &__list {
            margin: 0;
            padding: 0;
            display: flex;
        }
        &__icon {
            margin: 0 7px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: url("../img/home/social-icon-frame.png") no-repeat;
            background-size: contain;
            position: relative;
            height: 58px;
            width: 53px;
            @include breakpoint(medium) {
                height: 73px;
                width: 58px;
            }
            @include breakpoint(large) {
                height: 78px;
                width: 63px;
            }
            @include breakpoint(xlarge) {
                height: 83px;
                width: 68px;
                margin: 0 13px;
            }
            @include breakpoint(xxlarge) {
                height: 93px;
                width: 78px;
            }

            .fa {
                color: #fff;
                font-size: rem-calc(20px);
                text-align: center;
                width: 100%;
                margin-top: -6px;
                position: relative;
                @include breakpoint(medium) {
                    font-size: rem-calc(20px);
                    margin-top: -14px;
                }
                @include breakpoint(large) {
                    font-size: rem-calc(22px);
                }
                @include breakpoint(xlarge) {
                    font-size: rem-calc(25px);
                }
                @include breakpoint(xxlarge) {
                    font-size: rem-calc(35px);
                    margin-top: -15px;
                }
                &:after {
                    content: "";
                    width: 0;
                    height: 0;
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                    z-index: 1;
                    background: #fff;
                    transition: all .5s ease;
                }
            }
            &:hover {
                .fa:after {
                    box-shadow: 0px 0px 18px 14px rgba(255, 255, 255, 0.5);
                }
            }

            &:after {
                //content: "";
                display: block;
                position: absolute;
                margin-top: -7px;
                background: url("../img/home/social-sprite.png") no-repeat;
                width: 34px;
                height: 34px;
            }
            &--facebook {
                &:after {
                    background-position: 8px 0;
                }
            }
            &--twitter {
                &:after {
                    background-position: -90px 0;
                }
            }
            &--instagram {
                &:after {
                    background-position: -185px 0;
                }
            }
        }
    }
}

//============================================================
//==================== SMOKE
//============================================================
.smoke--light {
    width: 100px;
    height: 100px;
    background-image: url("../img/home/smoke-light.png");
    background-repeat: no-repeat;
    background-size: contain;
    position: absolute;
    top: 0;
    left: 0;
}

@function decimal-round ($number, $digits: 0, $mode: round) {
    $n: 1;
    // $number must be a number
    @if type-of($number) !=number {
        @warn '#{ $number } is not a number.';
        @return $number;
    }
    // $digits must be a unitless number
    @if type-of($digits) !=number {
        @warn '#{ $digits } is not a number.';
        @return $number;
    } @else if not unitless($digits) {
        @warn '#{ $digits } has a unit.';
        @return $number;
    }
    @for $i from 1 through $digits {
        $n: $n * 10;
    }
    @if $mode==round {
        @return round($number * $n) / $n;
    } @else if $mode==ceil {
        @return ceil($number * $n) / $n;
    } @else if $mode==floor {
        @return floor($number * $n) / $n;
    } @else {
        @warn "#{ $mode } is undefined keyword.";
        @return $number;
    }
}

@function decimal-ceil ($number, $digits: 0) {
    @return decimal-round($number, $digits, ceil);
}

@function decimal-floor ($number, $digits: 0) {
    @return decimal-round($number, $digits, floor);
}

@function v($x, $y) {
    $start-x: -200px;
    $end-x: 1920px;
    $steps-x: 15;
    $steps-x-value: (($start-x - $end-x) / $steps-x) * -1;

    $start-y: -100px;
    $end-y: 600px;
    $steps-y: 15;
    $steps-y-value: (($start-y - $end-y) / $steps-y) * -1;

    $x-list: ();
    $y-list: ();

    $nextval: $start-x;
    @while $nextval < $end-x {
        $x-list: append($x-list, $nextval);
        $nextval: $nextval + $steps-x-value;
    }

    $nextval: $start-y;
    @while $nextval < $end-y {
        $y-list: append($y-list, $nextval);
        $nextval: $nextval + $steps-y-value;
    }

    @return nth($x-list, $x),
    nth($y-list, $y);
}

@function randomVList() {
    $x-maxsteps: 15;
    $y-maxsteps: 15;
    $x: random($x-maxsteps - 10); //Startvalue
    $y: random($y-maxsteps - 10); //Startvalue
    $list: ();
    $list: append($list, $x $y);
    $random-x-steps: random($x-maxsteps - 5) + 5;
    @while $x < $random-x-steps {
        $x: $x + 1;
        @if $y==$y-maxsteps {
            $y: $y + (random(2) - 2); //y + [-1|0]
        } @else if $y==1 {
            $y: $y + (random(2) - 1); //y + [0|1]
        } @else {
            $y: $y + (random(3) - 2); //y + [-1|0|1]
        }
        $list: append($list, $x $y);
    }
    @return $list;
}

@mixin animation($name, $tupleList) {
    @keyframes #{$name} {
        $steps: length($tupleList);
        //@debug "Steps: " + $steps;
        @if $steps > 1 {
            @for $i from 0 to $steps {
                // <start> to <end> -> not include <end>
                $percentage: decimal-round($i*(100/($steps - 1)), 2); //calc percentage with 2 decimals
                #{$percentage}% {
                    $tuple: nth($tupleList, $i+1);
                    transform: translate(v(nth($tuple, 1), nth($tuple, 2)));
                }
            }
            0% {
                opacity: 0
            }
            15% {
                opacity: 1
            }
            85% {
                opacity: 1
            }
            100% {
                opacity: 0
            }
        }
    }
}

//Create random animations
@for $i from 1 to 20 {
    $randomlist: randomVList();
    @while length($randomlist) < 3 {
        $randomlist: randomVList();
    }
    @include animation("animation" + $i, $randomlist);
}

.particle {
    position: absolute;
    opacity: 0;
    overflow: visible;
    background-size: contain; //auto|length|cover|contain|initial|inherit
}

@for $i from 1 to 20 {
    .elem#{$i} {
        $position: v(random(10), random(10));
        left: nth($position, 1);
        top: nth($position, 2);
        //$duration: 12+ random(7);
        //for demo:
        $duration: 30 + random(20);
        animation: animation#{$i} linear #{$duration}s infinite;
        $size: 150px + random(20);
        height: $size;
        width: $size;
        $imglist: url(../img/home/smoke-light.png),
        url(../img/home/smoke-light.png),
        url(../img/home/smoke-light.png),
        url(../img/home/smoke-light.png),
        url(../img/home/smoke-light.png),
        url(../img/home/smoke-light.png);
        background-image: nth($imglist, ($i % 5) + 1);
    }
}

@for $i from 1 through 20 {
    .anim-delay#{$i} {
        //$animationdelay: random(20);
        //for demo:
        $animationdelay: random(5);
        animation-delay: #{$animationdelay}s;
    }
}

//============================================================
//====================  STORY
//============================================================
.story {
    &-wrapper {
        .parchment-bg {
            padding-bottom: 80px;
        }
    }
    &__box {
        max-width: 870px;
        width: 100%;
        margin: 0 auto;
        display: flex;
        position: relative;
        margin-top: 30px;
        margin-bottom: 45px;
        justify-content: center;
        flex-wrap: wrap;
        @include breakpoint(xlarge) {
            flex-wrap: nowrap;
        }
        @include breakpoint(xlarge) {
            margin-bottom: 74px;
        }
        &:after {
            content: "";
            height: 22px;
            display: block;
            position: absolute;
            bottom: -22px;
            left: 0;
            right: 0;
            background: url("../img/home/story__shadow.png") no-repeat;
            background-size: cover;
            @include breakpoint(xlarge) {
                left: -25%;
                right: -25%;
            }
        }
        &__image {
            img {
                height: 100%;
            }
        }
        &__content {
            &--second {
                .decor {
                    transform: translateX(40%);
                }
            }
            &__text {
                max-width: 517px;
                width: 100%;
                color: $secondary-color;
                font-weight: 700;
                line-height: 1.63;
                font-size: rem-calc(24px);
                margin-left: 20px;
                @include breakpoint(large) {
                    font-size: rem-calc(28px);
                    margin-left: 30px;
                }
                @include breakpoint(xxlarge) {
                    font-size: rem-calc(30px);
                    margin-left: 36px;
                }
                &--small {
                    max-width: 386px;
                    text-align: center;
                    @include breakpoint(xlarge) {
                        text-align: right;
                    }
                }
            }
        }
    }
}

//============================================================
//==================== GAME ECONOMY
//============================================================
.game-economy {
    &__divider {
        height: 163px;
        width: 100%;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        margin-bottom: 20px;
        @include breakpoint(large) {
            margin-bottom: 25px;
        }
        @include breakpoint(xlarge) {
            margin-bottom: 30px;
        }
        @include breakpoint(xxlarge) {
            margin-bottom: 35px;
        }
        &--first {
            background-image: url("../img/home/game-economy-divider-1.png");
        }
        &--second {
            background-image: url("../img/home/game-economy-divider-2.png");
        }
    }
    .text {
        margin-bottom: 54px;
        @include breakpoint(large) {
            margin-bottom: 64px;
        }
        @include breakpoint(xlarge) {
            margin-bottom: 74px;
        }
        @include breakpoint(xxlarge) {
            margin-bottom: 84px;
        }
    }
}

//============================================================
//==================== REGISTRATION
//============================================================
.registration {
    &__form {
        max-width: 370px;
        width: 100%;
        margin: 0 auto;
        padding-bottom: 35px;
        padding-left: 15px;
        padding-right: 15px;
        @include breakpoint(medium) {
            padding-right: 0;
            padding-left: 0;
        }
        .button {
            margin: 35px auto;
            margin-bottom: 0;
        }
    }
}

//============================================================
//==================== CARDS
//============================================================
.cards {
    .hexagon-box {
        padding-top: 70px;
        padding-bottom: 90px;
    }
    .hexagon {
        position: relative;
        margin-left: auto;
        margin-right: auto;
        &__point {
            cursor: pointer;
            display: block;
            border-radius: 100%;
            width: 104px;
            height: 104px;
            position: absolute;
            z-index: 10;
            &:after {
                content: "";
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                border-radius: 100%;
                transform: scale(0.7);
                z-index: -1;
                transform-origin: center;
                background-color: #f2d2a9;
                //z-index: -2;
            }
            &__icon {
                box-shadow: 0 6px 7px rgba(167, 138, 121, 0.38);
                transition: opacity .4s ease;
                transform-origin: center;
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                opacity: 0.6;
                display: block;
                background-image: url("../img/home/sprite_cart.png");
                background-repeat: no-repeat;
                border-radius: 100%;
                transform: scale(0.8);
                &:hover {
                    opacity: 1 !important;
                }
                &--first {
                    background-position: -5px 0;
                }
                &--second {
                    background-position: -116px 0;
                }
                &--three {
                    background-position: -226px 0px;
                }
                &--four {
                    background-position: -559px 0;
                }
                &--five {
                    background-position: -448px -1px;
                }
                &--six {
                    transform: scale(1);
                    background-position: -338px -2px;
                }
            }
            &--first {
                top: -27%;
                left: 0;
                transform: translateX(-50%);
            }
            &--second {
                bottom: -23%;
                left: 0;
                transform: translateX(-50%);
            }
            &--three {
                bottom: -73%;
                left: 50%;
                transform: translateX(-50%);
            }
            &--four {
                top: 73%;
                right: 0;
                transform: translateX(50%);
            }
            &--five {
                top: -27%;
                right: 0;
                transform: translateX(50%);
            }
            &--six {
                top: -73%;
                left: 46%;
                transform: translateX(-50%) scale(0.8);
                transform: matrix(1, 0, 0, 1, -37, 0);
                opacity: 1;
            }
        }
        &__content {
            width: 80%;
            text-align: center;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            visibility: hidden;
            opacity: 0;
            &[data-content="six"] {
                opacity: 1;
                visibility: inherit;
            }
        }
        &__title {
            color: $primary-color;
            font-size: rem-calc(26px);
            font-weight: 700;
            line-height: 1.53;
            @include breakpoint(xlarge) {
                font-size: rem-calc(30px);
            }
        }
        &__text {
            color: $secondary-color;
            font-size: rem-calc(18px);
            font-weight: 400;
            line-height: 1.6;
            @include breakpoint(xlarge) {
                font-size: rem-calc(20px);
            }
        }
    }
    &__box {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin-top: 20px;
        margin-bottom: 35px;
        @include breakpoint(xlarge) {
            margin-bottom: 80px;
        }
        &__card {
            margin-bottom: 20px;
            @include breakpoint(450px) {
                margin-bottom: 0;
                margin-right: 45px;
            }
            img {
                max-width: 526px;
                max-height: 312px;
                @include breakpoint(large) {
                    max-height: 412px;
                }
                @include breakpoint(xlarge) {
                    max-height: 512px;
                }
                @include breakpoint(xxlarge) {
                    max-height: 712px;
                }
            }
        }
        &__card-elements {
            padding-top: 20px;
            .head {
                color: $primary-color;
                font-family: $seondary-font-family;
                font-weight: 900;
                line-height: 1.4;
                text-transform: uppercase;
                font-size: rem-calc(20px);
                margin-bottom: 15px;
                @include breakpoint(450px) {
                    margin-bottom: 25px;
                }
                @include breakpoint(xlarge) {
                    font-size: rem-calc(21px);
                }
                @include breakpoint(xxlarge) {
                    font-size: rem-calc(23px);
                }
            }
            ul {
                padding: 0;
                margin: 0;
                li {
                    list-style: none;
                    display: block;
                    color: $primary-color;
                    font-family: $seondary-font-family;
                    font-weight: 900;
                    line-height: 2.2;
                    text-transform: uppercase;
                    font-size: rem-calc(14px);
                    position: relative;
                    @include breakpoint(450px) {
                        line-height: 3;
                    }
                    @include breakpoint(xlarge) {
                        font-size: rem-calc(15px);
                    }
                    @include breakpoint(xxlarge) {
                        font-size: rem-calc(16px);
                    }
                    &:before {
                        content: "";
                        width: 9px;
                        height: 9px;
                        display: inline-block;
                        background-image: url("../img/home/pimka.png");
                        background-repeat: no-repeat;
                        background-size: contain;
                        margin-right: 15px;
                    }
                }
            }
        }
    }
    &__flex {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        @include breakpoint(large) {
            margin-top: 75px;
            padding-bottom: 50px;
        }
        &__box {
            max-width: 210px;
            min-height: 300px;
            width: 100%;
            flex: 1 1 210px;
            position: relative;
            padding-top: 50px;
            box-sizing: content-box;
        }
        .card {
            width: 100%;
            height: 100%;
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center;
            &--basik {
                background-image: url("../img/home/card3.png");
            }
            &--rare {
                background-image: url("../img/home/card4.png");
            }
            &--epic {
                background-image: url("../img/home/card3.png");
            }
            &--legendary {
                background-image: url("../img/home/card4.png");
            }
            &__title {
                color: $primary-color;
                font-family: $seondary-font-family;
                font-size: 16px;
                font-weight: 900;
                line-height: 3;
                text-transform: uppercase;
                text-align: center;
                position: absolute;
                left: 50%;
                top: 0;
                transform: translateX(-50%);
            }
        }
    }
}

.hexagon {
    position: relative;
    width: 352px;
    height: 203.23px;
    background-color: transparent;
    margin: 101.61px 0;
    border-left: dotted 3px #a87d4e;
    border-right: dotted 3px #a87d4e;
    &:before,
    &:after {
        content: "";
        position: absolute;
        z-index: 1;
        width: 248.90px;
        height: 248.90px;
        -webkit-transform: scaleY(0.5774) rotate(-45deg);
        -ms-transform: scaleY(0.5774) rotate(-45deg);
        transform: scaleY(0.5774) rotate(-45deg);
        background-color: inherit;
        left: 48.5492px;
    }
    &:before {
        top: -124.4508px;
        border-top: dotted 4.2426px #a87d4e;
        border-right: dotted 4.2426px #a87d4e;
    }
    &:after {
        bottom: -124.4508px;
        border-bottom: dotted 4.2426px #a87d4e;
        border-left: dotted 4.2426px #a87d4e;
    }
}

//============================================================
//==================== OFFCHAIN CARDS
//============================================================
.offchain-cards {
    .container__content > .text-center > .title {
        margin-bottom: 25px;
    }
    .chain__box {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-bottom: 50px;
        &__card {
            position: relative;
            width: 203px;
            height: 277px;
            overflow: hidden;
            margin-bottom: 20px;
            @include breakpoint(large) {
                margin-bottom: 30px;
            }
            @include breakpoint(xlarge) {
                margin-bottom: 40px;
            }
            &:hover {
                .chain__box__card__hover {
                    transform: translateY(0);
                }
                .chain__box__card__bottom {
                    transform: translateX(-51%) translateY(100%);
                }
            }
            &__hover {
                display: block;
                width: 103%;
                height: 160px;
                background-image: url("../img/home/card-hover.png");
                background-repeat: no-repeat;
                background-size: contain;
                position: absolute;
                bottom: -14px;
                left: -2px;
                transform: translateY(100%);
                transition: transform .5s ease;
                &__content {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    padding-top: 15px;
                }
                &__title {
                    color: $primary-color;
                    font-family: $seondary-font-family;
                    font-size: rem-calc(16px);
                    font-weight: 700;
                    line-height: 2;
                }
                &__text {
                    color: black;
                    font-size: rem-calc(14px);
                    font-weight: 700;
                    line-height: 2.14;
                }

            }
            &__bottom {
                display: flex;
                align-items: center;
                justify-content: center;
                position: absolute;
                left: 50%;
                bottom: -1px;
                transform: translateX(-51%);
                width: 100.5%;
                height: 50px;
                color: $primary-color;
                font-family: $seondary-font-family;
                font-size: rem-calc(16px);
                font-weight: 700;
                //background-color: rgba(249, 226, 187, 0.9);
                background-image: url("../img/home/bottom_card.png");
                background-repeat: no-repeat;
                background-size: contain;
                transition: transform .3s ease;
                &__text {
                    margin-top: 8px;
                }
            }
        }
    }
}

//============================================================
//==================== GAME TUTORIAL
//============================================================
.game_tutorial {
    .container-slider {
        margin-top: 20px;
        margin-bottom: 40px;
        @include breakpoint(xlarge) {
            margin-top: 30px;
            margin-bottom: 50px;
        }
    }
    .title-slider {
        color: #47110a;
        font-family: $seondary-font-family;
        font-size: 23px;
        font-weight: 900;
        line-height: 1.4;
        text-transform: uppercase;
        text-align: center;
        margin-top: 35px;
        @include breakpoint(xlarge) {
            margin-top: 55px;
        }
    }
    .game_elements {
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
        margin-top: 40px;
        margin-bottom: 43px;
        @include breakpoint(large) {
            margin-top: 60px;
            margin-bottom: 53px;
        }
        @include breakpoint(xlarge) {
            margin-top: 100px;
            margin-bottom: 93px;
        }
        &__left {

        }
        &__right {
            &__title {
                color: #47110a;
                font-family: $seondary-font-family;
                font-size: 23px;
                font-weight: 900;
                line-height: 1.4;
                text-transform: uppercase;
                margin-bottom: 22px;
            }
        }
        &__list {
            margin: 0;
            padding: 0;
            &__item {
                list-style: none;
                &.is-active {
                    background-color: rgba(192, 175, 150, 0.2);
                    border: 1px solid #b09567;
                    border-left: none;
                    border-right: none;
                }
                &:before {
                    content: "";
                    width: 9px;
                    height: 9px;
                    display: inline-block;
                    background-image: url(../img/home/pimka.png);
                    background-repeat: no-repeat;
                    background-size: contain;
                    margin-right: 10px;
                }
            }
            &__link {
                font-family: $seondary-font-family;
                font-size: 16px;
                font-weight: 900;
                line-height: 3;
                text-transform: uppercase;
                color: #47110a;
            }
        }
    }
}

//============================================================
//==================== GAME
//============================================================
.game {
    .parchment-bg {
        position: relative;
        padding-bottom: 34px;
        @include breakpoint(large) {
            padding-bottom: 44px;
        }
        @include breakpoint(xlarge) {
            padding-bottom: 64px;
        }
    }
    .btn-box {
        margin-top: 35px;
        @include breakpoint(xlarge) {
            margin-top: 45px;
        }
        .button {
            margin-left: auto;
            margin-right: auto;
        }
    }
}

.download_box {
    width: 659px;
    height: 202px;
    position: relative;
    padding-top: 20px;
    &__title {
        color: $primary-color;
        font-family: $seondary-font-family;
        font-size: rem-calc(23px);
        font-weight: 900;
        line-height: 1.4;
        text-align: center;
        text-transform: uppercase;
    }
    &__frame {
        width: inherit;
        height: inherit;
        position: absolute;
        left: 0;
        top: 0;
        background: url("../img/home/frame_download.png") no-repeat;
        background-size: cover;
        z-index: -1;
    }
    &__buttons {
        display: flex;
        align-items: center;
        justify-content: space-around;
        height: 86%;
    }
    &__button {
        border: none;
        outline: none;
        box-shadow: none;
        -webkit-appearance: none;
        width: 280px;
        height: 108px;
        display: block;
        background-repeat: no-repeat;
        background-size: contain;
        &--android {
            background-image: url("../img/home/android_btn.png");
        }
        &--ios {
            background-image: url("../img/home/ios_btn.png");
        }
    }
}

//============================================================
//==================== LEADER BOARD
//============================================================
.leader_board {
    .container__content {
        > .text-center > .title {
            margin-bottom: 20px;
            @include breakpoint(medium) {
                margin-bottom: 40px;
            }
        }
    }
    .box_table {
        margin-bottom: 35px;
        position: relative;
        &:after, &:before {
            content: "";
            display: block;
            position: absolute;
            left: -25px;
            top: 0;
            width: 25px;
            height: 50px;
            background-image: url("../img/home/filter_angle.png");
            background-repeat: no-repeat;
            background-size: contain;
        }
        &:after {
            left: auto;
            right: -25px;
            transform: rotate(180deg);
        }
        @include breakpoint(large) {
            margin-bottom: 45px;
        }
        @include breakpoint(xlarge) {
            margin-bottom: 60px;
        }
    }
}

.table-style {
    width: 100%;
    text-align: left;
    thead {
        th {
            //background-color: rgba(192, 175, 150, 0.2);
            //border: 1px solid #b09567;
            background-image: url("../img/home/filter_frame.png");
            background-repeat: repeat-x;
            border-left: none;
            border-right: none;
            color: #a7732c;
            font-family: $seondary-font-family;
            font-size: rem-calc(16px);
            font-weight: 900;
            text-transform: uppercase;
        }
    }
    tr {
        td, th {
            padding: 14px 20px;
            &:last-child {
                text-align: right;
            }
        }
    }
    tr:nth-child(even) {
        background-color: rgba(225, 197, 150, 0.4);
    }
    tr:nth-child(odd) {

    }
    tr > td, tr > th {
        &:first-child {
            padding-left: 20px;
            @include breakpoint(large) {
                padding-left: 37px;
            }
            @include breakpoint(xlarge) {
                padding-left: 57px;
            }
        }
        &:last-child {
            padding-right: 20px;
            @include breakpoint(large) {
                padding-right: 40px;
            }
            @include breakpoint(xlarge) {
                padding-right: 60px;
            }
        }
    }
    tr > td {
        color: black;
        font-size: rem-calc(16px);
        font-weight: 700;
        line-height: normal;
        text-transform: uppercase;
        border-bottom: 1px solid #e2c697;
        &:first-child, &:last-child {
            color: $primary-color;
        }
    }
}

//============================================================
//==================== LOGIN
//============================================================
.login {
    &__form {
        max-width: 370px;
        width: 100%;
        padding-left: 15px;
        padding-right: 15px;
        margin: 0 auto;
        @include breakpoint(medium) {
            padding-left: 0;
            padding-right: 0;
        }
        .button {
            margin-top: 40px;
            margin-left: auto;
            margin-right: auto;
        }
    }
    &__not_account {
        text-align: center;
        margin-top: 55px;
        .button {
            margin-left: auto;
            margin-right: auto;
        }
    }
    .parchment-bg {
        padding-bottom: 30px;
        @include breakpoint(medium) {
            padding-bottom: 50px;
        }
        @include breakpoint(large) {
            padding-bottom: 80px;
        }
        @include breakpoint(xlarge) {
            padding-bottom: 106px;
        }
    }
}

//============================================================
//==================== MEDIA
//============================================================
.gallery {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
    height: 100%;
    margin-top: 20px;
    @include breakpoint(large) {
        margin-top: 30px;
    }
    @include breakpoint(xlarge) {
        margin-top: 35px;
    }
    &__item {
        width: 300px;
        height: 243px;
        padding: 10px;
        cursor: pointer;
        &__image {
            transition: all .4s ease;
            opacity: 0.8;
            &:hover {
                opacity: 1;
                box-shadow: 0 9px 9px rgba(128, 89, 38, 0.48);
            }
        }
    }
}

.media {
    .parchment-bg {
        padding-bottom: 0;
        @include breakpoint(medium) {
            padding-bottom: 40px;
        }
        @include breakpoint(large) {
            padding-bottom: 50px;
        }
        @include breakpoint(xlarge) {
            padding-bottom: 86px;
        }
    }
    .container__content {
        > .text-center > .title {
            margin-bottom: 25px;
        }
    }
    .filter-panel {
        margin-bottom: 50px;
    }
    &__title {
        color: $primary-color;
        font-family: $body-font-family;

        font-weight: 700;
        line-height: 0.94;
        text-align: center;
        font-size: rem-calc(24px);
        @include breakpoint(medium) {
            font-size: rem-calc(28px);
        }
        @include breakpoint(large) {
            font-size: rem-calc(30px);
        }
        @include breakpoint(xlarge) {
            font-size: rem-calc(34px);
        }
    }
    .video {
        margin-top: -25px;
        @include breakpoint(medium) {
            margin-top: -35px;
        }
        &:not(:last-child) {
            margin-bottom: 40px;
            @include breakpoint(large) {
                margin-bottom: 60px;
            }
            @include breakpoint(xlarge) {
                margin-bottom: 80px;
            }
        }
        &:last-child {
            margin-bottom: 0;
        }
        &__decor {
            background-position: 0;
        }
    }

    &--screens {
        .media__title {
            margin-top: 30px;
            @include breakpoint(large) {
                margin-top: 45px;
            }
            @include breakpoint(xlarge) {
                margin-top: 66px;
            }
        }
        .filter-panel {
            margin-bottom: 20px;
        }
    }
    &--audio {
        .parchment-bg {
            background-size: cover;
        }
        &__track {
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-bottom: 1px solid #e1c596;
            padding: 10px 20px 10px 50px;
            position: relative;
            cursor: pointer;
            &:nth-child(even) {
                background-color: rgba(225, 197, 150, 0.4);
            }
            &__play {
                display: inline-block;
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 6px 0 6px 9px;
                border-color: transparent transparent transparent $primary-color;
                position: absolute;
                left: 22px;
                top: 50%;
                transform: translateY(-50%);
            }
            &__pause {
                border: 4px solid $primary-color;
                border-top: none;
                border-bottom: none;
                width: 10px;
                height: 10px;
                position: absolute;
                left: 22px;
                top: 50%;
                transform: translateY(-50%);
            }
            &__name {
                color: black;
                font-family: $body-font-family;
                font-size: rem-calc(16px);
                font-weight: 700;
                line-height: 1.875;
            }
            &__time {
                color: $primary-color;
                font-family: $body-font-family;
                font-size: rem-calc(16px);
                font-weight: 700;
                text-transform: uppercase;
                cursor: pointer;
            }
            &.is-play {
                .media--audio__track__play {
                    display: inline-block;
                }
            }
        }
    }
}

//============================================================
//==================== COMMUNITY
//============================================================
.community {
    &__list_wrap {
        text-align: center;
        margin-top: 27px;
        @include breakpoint(large) {
            margin-top: 37px;
        }
        @include breakpoint(xlarge) {
            margin-top: 57px;
        }
    }
    &__list {
        margin: 0;
        padding: 0;
        display: inline-block;
        &__item {
            list-style: none;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            margin-bottom: 15px;
            @include breakpoint(large) {
                margin-bottom: 20px;
            }
            @include breakpoint(xlarge) {
                margin-bottom: 25px;
            }
        }
        &__link {
            color: $primary-color;
            font-family: $seondary-font-family;
            font-weight: 900;
            line-height: 1.4;
            text-transform: uppercase;
            font-size: rem-calc(16px);
            @include breakpoint(medium) {
                font-size: rem-calc(18px);
            }
            @include breakpoint(large) {
                font-size: rem-calc(20px);
            }
            @include breakpoint(xlarge) {
                font-size: rem-calc(23px);
            }
        }
        &__icon {
            display: inline-block;
            width: 64px;
            height: 64px;
            background-color: rgba(243, 214, 165, 0.5);
            border: 2px solid #b09567;
            border-radius: 100%;
            margin-right: 18px;
            background-image: url("../img/home/community-sprite.png");
            background-repeat: no-repeat;
            &--slack {
                background-position: 9px 10px;
            }
            &--forum {
                background-position: 9px -81px;
            }
            &--facebook {
                background-position: 9px -169px;
            }
        }
    }
    .parchment-bg {
        padding-bottom: 80px;
        @include breakpoint(medium) {
            padding-bottom: 150px;
        }
        @include breakpoint(large) {
            padding-bottom: 180px;
        }
        @include breakpoint(xlarge) {
            padding-bottom: 215px;
        }
    }
}

//============================================================
//==================== ACCOUNT
//============================================================
.account {
    .parchment-bg {
        padding-bottom: 80px;
        background-size: cover;
        @include breakpoint(medium) {
            padding-bottom: 120px;
        }
        @include breakpoint(large) {
            padding-bottom: 150px;
        }
        @include breakpoint(xlarge) {
            padding-bottom: 190px;
        }
    }
    &__wrap {
        text-align: center;
        .button {
            display: inline-block;
        }
    }
    &__list {
        padding: 0;
        margin: 0;
        display: inline-block;
        margin-bottom: 30px;
        @include breakpoint(large) {
            margin-bottom: 40px;
        }
        @include breakpoint(xlarge) {
            margin-bottom: 57px;
        }
        &__item {
            list-style: none;
            display: flex;
            align-items: center;
            margin-bottom: 15px;
            @include breakpoint(large) {
                margin-bottom: 20px;
            }
            @include breakpoint(xlarge) {
                margin-bottom: 25px;
            }
        }
        &__text {
            color: $primary-color;
            font-family: $seondary-font-family;

            font-weight: 900;
            line-height: 1.4;
            text-transform: uppercase;
            font-size: rem-calc(16px);
            @include breakpoint(medium) {
                font-size: rem-calc(18px);
            }
            @include breakpoint(large) {
                font-size: rem-calc(20px);
            }
            @include breakpoint(xlarge) {
                font-size: rem-calc(23px);
            }
        }
        &__icon {
            width: 64px;
            height: 64px;
            background-color: rgba(243, 214, 165, 0.5);
            border: 2px solid #b09567;
            display: inline-block;
            background-image: url("../img/home/account-sprite.png");
            background-repeat: no-repeat;
            border-radius: 100%;
            margin-right: 15px;
            &--email {
                background-position: 14px 17px;
            }
            &--name {
                background-position: 13px -69px;
            }
            &--counterparty {
                background-position: 13px -160px;
            }
        }
    }
}

//============================================================
//====================MEDIA QUERIES
//============================================================
//iPhone 5 & 5S in portrait & landscape
@media only screen and (min-device-width: 320px) and (max-device-width: 568px) {
    .title__bottom {
        width: 290px;
    }
}

@media only screen and (min-device-width: 375px) // or 213.4375em
and (max-device-width: 667px) // or 41.6875em
and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
    .video {
        height: 320px;
        &__decor {
            //background-position: 0 22px;
        }
    }
}

//TODO еффект для кнопки при ховере
//TODO логотип
//TODO фон для страници
// TODO підготувати іконкт для шестикутника, непрозорі та без скейлу
//TODO картки в cards.html

